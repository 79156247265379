import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import "./botparameters.css";

// GraphQL Queries and Mutations
const GET_BOT_PARAMETERS = gql`
    query GetBotParameters($email: String!) {
        getBotParameters(email: $email) {
            sellTarget
            buyTarget
            buyNumber
            tradeTime
            halfDayTradeTime
            maxBuyPercentage
            cashReserve
        }
    }
`;

const UPDATE_BOT_PARAMETERS = gql`
    mutation UpdateBotParameters($email: String!, $parameters: BotParametersInput!) {
        updateBotParameters(email: $email, parameters: $parameters) {
            success
            message
        }
    }
`;

// Define defaultValues outside the component
const defaultValues = {
    sellTarget: "",
    buyTarget: "",
    buyNumber: "",
    tradeTime: "",
    halfDayTradeTime: "",
    maxBuyPercentage: "",
    cashReserve: "",
};

const BotParameters = ({ email }) => {
    const { data, loading, error } = useQuery(GET_BOT_PARAMETERS, {
        variables: { email },
    });

    const [updateBotParameters] = useMutation(UPDATE_BOT_PARAMETERS);
    const [formValues, setFormValues] = useState(defaultValues);

    useEffect(() => {
        if (data && data.getBotParameters) {
            // Ensure null values are replaced with empty strings
            const sanitizedData = Object.keys(data.getBotParameters).reduce((acc, key) => {
                acc[key] = data.getBotParameters[key] ?? ""; // Replace null with empty string
                return acc;
            }, {});
            setFormValues({ ...defaultValues, ...sanitizedData });
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        try {
            if (formValues.sellTarget <= 0) {
                throw Error("Sell target must be greater than 0.");
            }
            if (formValues.buyTarget <= 0) {
                throw Error("Buy target must be greater than 0.");
            }
            if (formValues.buyNumber <= 0) {
                throw Error("Buy number must be greater than 0.");
            }
            
            const formattedValues = {
                sellTarget: parseFloat(formValues.sellTarget) || 0,
                buyTarget: parseFloat(formValues.buyTarget) || 0,
                buyNumber: parseInt(formValues.buyNumber, 10) || 0,
                tradeTime: formValues.tradeTime || "00:00",
                halfDayTradeTime: formValues.halfDayTradeTime || "00:00",
                maxBuyPercentage: parseFloat(formValues.maxBuyPercentage) || 0,
                cashReserve: parseFloat(formValues.cashReserve) || 0,
            };

            const response = await updateBotParameters({
                variables: {
                    email,
                    parameters: formattedValues,
                },
            });
            alert(response.data.updateBotParameters.message);
        } catch (err) {
            console.error(err);
            alert(err);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="botparameters-container">
            <h2>Bot Parameters</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="sellTarget">Sell Target (%):</label>
                    <input
                        id="sellTarget"
                        name="sellTarget"
                        type="text"
                        value={formValues.sellTarget}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="buyTarget">Buy Target (%):</label>
                    <input
                        id="buyTarget"
                        name="buyTarget"
                        type="text"
                        value={formValues.buyTarget}
                        placeholder="Enter Buy Target"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="buyNumber">Buy Number:</label>
                    <input
                        id="buyNumber"
                        name="buyNumber"
                        type="text"
                        value={formValues.buyNumber}
                        placeholder="Enter Buy Number"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="tradeTime">Trade Time (PST):</label>
                    <input
                        id="tradeTime"
                        name="tradeTime"
                        type="text"
                        value={formValues.tradeTime}
                        placeholder="Enter Trade Time"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="halfDayTradeTime">Half Day Trade Time (PST):</label>
                    <input
                        id="halfDayTradeTime"
                        name="halfDayTradeTime"
                        type="text"
                        value={formValues.halfDayTradeTime}
                        placeholder="Enter Half Day Trade Time"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="maxBuyPercentage">Max Buy Percentage (%):</label>
                    <input
                        id="maxBuyPercentage"
                        name="maxBuyPercentage"
                        type="text"
                        value={formValues.maxBuyPercentage}
                        placeholder="Enter Max Buy Percentage"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="cashReserve">Cash Reserve:</label>
                    <input
                        id="cashReserve"
                        name="cashReserve"
                        type="text"
                        value={formValues.cashReserve}
                        placeholder="Enter Cash Reserve"
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="botparameters-submit-button">
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default BotParameters;
