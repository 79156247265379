import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { symbols } from './utils/symbols';
import { backtestTitles } from './utils/backtestTitles';
// Components
import NavBar from './components/NavBar';
import Header from './components/Header';

// Pages
import Home from './pages/Home/index';
import Terms from './pages/Terms/index';
import Stock from './pages/Stock';
import Account from './pages/Account';
import Subscribe from './pages/Subscribe';
import Logout from './pages/Logout';
import SubscribeSuccess from './pages/SubscribeSuccess';

// Utils
import Auth from './utils/auth';

var uriString = 'http://localhost:4000/graphql';

if (process.env.NODE_ENV === 'production') {
  uriString = '/graphql';
}
const httpLink = createHttpLink({
  uri: uriString
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers
    }
  }
});
const client = new ApolloClient({
  connectToDevTools: false,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


function App() {
  const loggedin = Auth.loggedIn();
  const stockRoutes = [];

  var email, isSubscribed;
  if (loggedin) {
    email = Auth.getProfile().data.email;
    isSubscribed = Auth.getProfile().data.isSubscribed;
  } else {
    console.log('Not logged in');
    isSubscribed = 'false';
    email = '';
  }
  for (var i in symbols) {
    stockRoutes.push(<Route exact path={`/${symbols[i][0]}`} key={`${symbols[i][0]}`} element={<Stock symbol={symbols[i][0]} loggedin={loggedin} isSubscribed={isSubscribed} />} />)
  }

  return (
    <ApolloProvider client={client}>
      <div className="all">
        <Header />

        <Router>
          <Routes>
            <Route exact path="/" element={<Home loggedin={loggedin} email={email} />} />
            <Route exact path="/terms-of-use" element={<Terms loggedin={loggedin} />} />
            <Route exact path="/account" element={<Account loggedin={loggedin} email={email} isSubscribed={isSubscribed} />} />
            <Route exact path="/subscribe" element={<Subscribe loggedin={loggedin} />} />
            <Route exact path="/logout" element={<Logout />} />
            {stockRoutes}
          </Routes>
        </Router>



        <NavBar loggedin={loggedin} />
      </div>
    </ApolloProvider>
  );
}

export default App;
