import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_CURRENT_PRICE } from "../../../utils/queries";

const WatchListHint = ({ symbol }) => {
    const currentPriceQuery = useQuery(QUERY_CURRENT_PRICE, { variables: { symbol: symbol }});
    const [currentPrice, setCurrentPrice] = useState(0);
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentPriceQuery.refetch) {
                currentPriceQuery.refetch().then((result) => {
                    if (result.data && result.data.getCurrentPrice) {
                        setCurrentPrice(result.data.getCurrentPrice.currentPrice);
                    }
                });
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [currentPriceQuery]);

    if (currentPriceQuery.loading) {
        return (<>...</>);
    }
    return (<div className="watchlist-hint">{currentPrice || "..."}</div>);
}

export default WatchListHint;