/*
Home Page
*/
import './home.css';



// Components
import Footer from '../../components/Footer'
import SearchStock from './SearchStock';
import VolatilityContainer from './VolatilityContainer';
import IPLogger from '../../components/Logger/IPLogger';
import HomeLoggedIn from './HomeLoggedIn';

const Home = ({ loggedin, email }) => {
    let securityLog = <></>;
    if (process.env.NODE_ENV === "production") {
        securityLog = <IPLogger email={email} />;
    } 
    

    if (loggedin) {
        return (<>
            <HomeLoggedIn email={email} />
        </>);
    }
    return (<div className="home-container">
        <div className="component">
            <SearchStock />
        </div>
        <VolatilityContainer />
        
        <div className="component">
            <p>Available Stocks!</p>
            <a href="SPY"><p>SPY</p></a>
            <a href="SPXL"><p>SPXL</p></a>
            <a href="SPXS"><p>SPXS</p></a>
            <a href="NVDA"><p>NVDA</p></a>
            <a href="NVDL"><p>NVDL</p></a>
            <a href="PLTR"><p>PLTR</p></a>
            <a href="PTIR"><p>PTIR</p></a>
            <a href="APH"><p>APH</p></a>
        </div>
        
        <Footer />
        {securityLog}
    </div>);
}

export default Home;