import React, { useState } from 'react';

// Components
import Footer from "../../components/Footer";
import VolatilityContainer from "./VolatilityContainer";
import TotalEquity from "./TotalEquity/TotalEquity";
import PositionsContainer from "./Positions/PositionsContainer";
import BotStatus from "./BotStatus/BotStatus";
import WatchList from './WatchList/WatchList';
import BotParameters from './BotParameters/BotParameters';

const HomeLoggedIn = ({ email }) => {
    const [cash, setCash] = useState(0); // State to hold the cash value
    const [totalEquity, setTotalEquity] = useState(0);

    // Callback to update the cash value
    const handleCashUpdate = (newCash) => {
        setCash(newCash);
    };
    // Callback to update total equity
    const handleTotalEquityUpdate = (total) => {
        setTotalEquity(total);
    };

    return (<>
        <div className="home-container">
            <div className="component">

            </div>
            <VolatilityContainer />
            <div className="joint-component">
                <TotalEquity total={totalEquity} cash={cash} />
                <BotStatus email={email} updateCash={handleCashUpdate} />
            </div>

            <PositionsContainer email={email} updateTotalEquity={handleTotalEquityUpdate} />
            <div className="joint-component">
                <WatchList email={email} />
                <BotParameters email={email} />
            </div>

            <Footer />
        </div>
    </>);
}

export default HomeLoggedIn;