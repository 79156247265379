import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { LOG_IP } from '../../utils/mutations';

const IPLogger = ({ email }) => {
    const [ip, setIP] = useState('');
    const [logIp] = useMutation(LOG_IP);

    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIP(data.ip);
                logIp({ variables: { email: email, ip: data.ip }});
            } catch (error) {
                
            }
        };

        fetchIP();
    }, []);
    
    return (<></>);
}

export default IPLogger;