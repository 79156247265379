import { useQuery } from '@apollo/client';
import { QUERY_CURRENT_PRICE } from '../../../utils/queries';
import LoadingComponent from "../LoadingComponent";

const StockTitle = ({ symbol }) => {
    const currentPriceQuery = useQuery(QUERY_CURRENT_PRICE, { variables: { symbol: symbol } });
    if (currentPriceQuery.loading) {
        return (<>
            <LoadingComponent symbol={symbol} />
        </>);
    }
    const currentPrice = currentPriceQuery.data?.getCurrentPrice?.currentPrice ?? 0; 
    
    
    return (
        <div className="company-title">
            <h2>{symbol}</h2>
            <h2></h2>
            <h2>CIK - 0000000000</h2>
            <h2>{currentPrice}</h2>
        </div>
    );
}

export default StockTitle;