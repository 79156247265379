import React from 'react';
import "./totalequity.css";

const TotalEquity = ({ total, cash }) => {
    const accountValue = total + cash; // Calculate total account value
    const equityPercentage = accountValue ? (total / accountValue) * 100 : 0; // Calculate equity percentage
    const cashPercentage = accountValue ? (cash / accountValue) * 100 : 0; // Calculate cash percentage

    return (
        <div className="total-equity">
            <table>
                <tbody>
                    <tr>
                        <th>Equity:</th>
                        <td>${total.toFixed(2)}</td>
                        <td>({equityPercentage.toFixed(2)}%)</td>
                    </tr>
                    <tr>
                        <th>Available Cash:</th>
                        <td>${cash.toFixed(2)}</td>
                        <td>({cashPercentage.toFixed(2)}%)</td>
                    </tr>
                    <tr>
                        <th>Account Value:</th>
                        <td>${accountValue.toFixed(2)}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TotalEquity;
