import React, { useEffect } from 'react';
import "./positions.css";
import { useQuery } from '@apollo/client';
import { GET_POSITIONS } from '../../../utils/queries';

const PositionsContainer = ({ email, updateTotalEquity }) => {
    // Use Apollo Client's `useQuery` hook
    const { loading, error, data, refetch } = useQuery(GET_POSITIONS, {
        variables: { user: email },
    });

    // Safely extract positions and calculate total equity
    const positions = data?.getPositions || []; // Ensure positions is an empty array if data is undefined
    const totalEquity = positions.reduce((sum, position) => {
        return sum + position.currentPrice * position.quantity;
    }, 0);

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 5000);
        return () => clearInterval(interval);
    }, [refetch]);
    
    // Update total equity in the parent component
    useEffect(() => {
        updateTotalEquity(totalEquity);
    }, [totalEquity, updateTotalEquity]); // Re-run effect if total equity changes

    // Handle loading and error states
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="positions-container">
            <h2>{email} Positions</h2>
            <table>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Quantity</th>
                        <th>Average Entry Price</th>
                        <th>Unrealized P/L (%)</th>
                        <th>Current Price</th>
                    </tr>
                </thead>
                <tbody>
                    {positions.map((position, index) => {
                        const profitLossClass =
                            position.unrealizedProfitLossPercentage > 0
                                ? "positive"
                                : position.unrealizedProfitLossPercentage < 0
                                ? "negative"
                                : "neutral";

                        // Calculate value in parentheses
                        const unrealizedValue = position.quantity * (position.currentPrice - position.averageEntryPrice);
                        const symbolSlug = "/" + position.symbol;
                        return (
                            <tr key={index}>
                                <td data-label="Symbol"><a href={symbolSlug}>{position.symbol}</a></td>
                                <td data-label="Quantity">{position.quantity.toFixed(4)}</td>
                                <td data-label="Avg Entry Price">${position.averageEntryPrice.toFixed(2)}</td>
                                <td data-label="Unrealized P/L (%)" className={profitLossClass}>
                                    {(position.unrealizedProfitLossPercentage * 100).toFixed(2)}% 
                                    &nbsp;
                                    ({unrealizedValue.toFixed(2)})
                                </td>
                                <td data-label="Current Price">${position.currentPrice.toFixed(2)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PositionsContainer;
