import './stock.css';
import Footer from '../../components/Footer';
import Patron from './Patron/Patron';
import StockTitle from './StockTitle';

const Stock = ({ symbol, loggedin, isSubscribed }) => {

    return (<div>
        <StockTitle symbol={symbol} />
        {/* <Patron /> */}
        
        <Footer />
    </div>);
}

export default Stock;